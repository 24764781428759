<template>
    <div class="dashboard">
        <div class="container-fluid">
            <div class="content-area">
                <div class="dashboard-content">
                    <div class="dashboard-header clearfix">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <h4>تعديل إعلان</h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="breadcrumb-nav">
                                    <ul>
                                        <li>
                                            <router-link to="/my"
                                                >الرئيسية</router-link
                                            >
                                        </li>
                                        <li class="active">تعديل إعلان</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column col-lg-12">
                            <div class="properties-box">
                                <div class="inner-container">
                                    <div class="property-submit-form">
                                        <vee-form
                                            @submit="submit"
                                            :validation-schema="
                                                PropertyFormValidation
                                            "
                                        >
                                            <div class="title">
                                                <h3>البيانات الأساسية</h3>
                                            </div>
                                            <div class="row">
                                                <!-- Form Group -->
                                                <div
                                                    class="form-group col-lg-4 col-md-6 col-sm-12"
                                                >
                                                    <label>عنوان الإعلان<span class="text-danger" > *</span></label>
                                                    <Field
                                                        v-model="property.title"
                                                        placeholder="عنوان الإعلان"
                                                        name="title"
                                                    />
                                                    <ErrorMessage
                                                        class="has-error"
                                                        name="title"
                                                    />
                                                </div>

                                                <!-- Form Group -->
                                                <div
                                                    class="form-group col-lg-4 col-md-6 col-sm-12"
                                                >
                                                    <label>نوع العقار<span class="text-danger" > *</span></label>
                                                    <Field
                                                        as="select"
                                                        v-model="
                                                            property.category_id
                                                        "
                                                        class="custom-select-box"
                                                        name="category_id"
                                                    >
                                                        <option
                                                            v-for="category in categories"
                                                            :key="category.id"
                                                            :value="category.id"
                                                        >
                                                            {{ category.title }}
                                                        </option>
                                                    </Field>
                                                </div>

                                                <!-- Form Group -->
                                                <div
                                                    class="form-group col-lg-4 col-md-6 col-sm-12"
                                                >
                                                    <label>المحافظة<span class="text-danger" > *</span></label>
                                                    <Field
                                                        as="select"
                                                        v-model="
                                                            property.governorate
                                                        "
                                                        placeholder="المحافظة"
                                                        name="governorate"
                                                        class="form-control"
                                                    >
                                                        <option value="">
                                                            تحديد محافظة
                                                        </option>
                                                        <option>مسقط</option>
                                                        <option>ظفار</option>
                                                        <option>مسندم</option>
                                                        <option>البريمى</option>
                                                        <option>
                                                            الداخلية
                                                        </option>
                                                        <option>
                                                            شمال الباطنة
                                                        </option>
                                                        <option>
                                                            جنوب الباطنة
                                                        </option>
                                                        <option>
                                                            جنوب الشرقية
                                                        </option>
                                                        <option>
                                                            شمال الشرقية
                                                        </option>
                                                        <option>الظاهرة</option>
                                                        <option>الوسطى</option>
                                                    </Field>
                                                    <ErrorMessage
                                                        class="has-error"
                                                        name="governorate"
                                                    />
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="row">
                                                        <!-- Form Group -->
                                                        <div
                                                            class="form-group col-12"
                                                        >
                                                            <label
                                                                >الولاية<span class="text-danger" > *</span></label
                                                            >
                                                            <Field
                                                                as="select"
                                                                v-model="
                                                                    property.state
                                                                "
                                                                placeholder="الولاية"
                                                                name="state"
                                                                class="form-control"
                                                            >
                                                                <option
                                                                    value=""
                                                                >
                                                                    تحديد
                                                                    الولاية
                                                                </option>
                                                                <option
                                                                    v-for="(
                                                                        state,
                                                                        index
                                                                    ) in availableStates"
                                                                    :key="index"
                                                                >
                                                                    {{ state }}
                                                                </option>
                                                            </Field>
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="governorate"
                                                            />
                                                        </div>




                                                            <!-- Form Group -->
                                                            <div
                                                                class="form-group col-6"
                                                            >
                                                                <label
                                                                    >المنطقة</label
                                                                >
                                                                <Field
                                                                    v-model="
                                                                        property.area
                                                                    "
                                                                    placeholder="المنطقة"
                                                                    name="area"
                                                                    class="form-control"
                                                                />
                                                            </div>


                                                            <!-- Form Group -->
                                                            <div
                                                                class="form-group col-6"
                                                            >
                                                                <label
                                                                    >الشارع</label
                                                                >
                                                                <Field
                                                                    v-model="
                                                                        property.street
                                                                    "
                                                                    placeholder="الشارع"
                                                                    name="street"
                                                                    class="form-control"
                                                                />
                                                            </div>


                                                            <!-- Form Group -->
                                                            <div
                                                                class="form-group col-12"
                                                            >
                                                                <label
                                                                    >علامة مميزة</label
                                                                >
                                                                <Field
                                                                    v-model="
                                                                        property.landmark
                                                                    "
                                                                    placeholder="علامة مميزة"
                                                                    name="landmark"
                                                                    class="form-control"
                                                                />
                                                            </div>

                                                        <!-- Form Group -->
                                                        <div
                                                            class="form-group col-12"
                                                        >
                                                            <label
                                                                >تفاصيل
                                                                إضافية</label
                                                            >
                                                            <Field
                                                                as="textarea"
                                                                v-model="
                                                                    property.details
                                                                "
                                                                placeholder="تفاصيل إضافية"
                                                                name="details"
                                                            />
                                                        </div>

                                                        <!-- Form Group -->
                                                        <div
                                                            class="form-group col-6 d-flex align-items-center"
                                                        >
                                                            <label
                                                                >إعلان حصرى فى
                                                                الموقع</label
                                                            >
                                                            <el-switch
                                                                :active-value="
                                                                    1
                                                                "
                                                                v-model="
                                                                    property.exclusive
                                                                "
                                                                :width="90"
                                                            ></el-switch>
                                                        </div>

                                                        <!-- Form Group -->
                                                        <div
                                                            class="col-6"
                                                        ></div>

                                                        <!-- Form Group -->
                                                        <div
                                                            class="form-group col-md-4"
                                                        >
                                                            <label
                                                                >عدد
                                                                الغرف<span class="text-danger" > *</span></label
                                                            >
                                                            <Field
                                                                type="number"
                                                                v-model="
                                                                    property.rooms
                                                                "
                                                                placeholder="عدد الغرف"
                                                                name="rooms"
                                                            />
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="rooms"
                                                            />
                                                        </div>

                                                        <!-- Form Group -->
                                                        <div
                                                            class="form-group col-md-4"
                                                        >
                                                            <label
                                                                >عدد
                                                                الأسرة<span class="text-danger" > *</span></label
                                                            >
                                                            <Field
                                                                type="number"
                                                                v-model="
                                                                    property.beds
                                                                "
                                                                placeholder="عدد الأسرة"
                                                                name="beds"
                                                            />
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="beds"
                                                            />
                                                        </div>

                                                        <!-- Form Group -->
                                                        <div
                                                            class="form-group col-md-4"
                                                        >
                                                            <label
                                                                >الأسرة
                                                                المزدوجة<span class="text-danger" > *</span></label
                                                            >
                                                            <Field
                                                                type="number"
                                                                v-model="
                                                                    property[
                                                                        'dual_beds'
                                                                    ]
                                                                "
                                                                placeholder="الأسرة المزدوجة"
                                                                name="dual_beds"
                                                            />
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="dual_beds"
                                                            />
                                                        </div>

                                                        <!-- Form Group -->
                                                        <div
                                                            class="form-group col-md-4"
                                                        >
                                                            <label
                                                                >الأسرة
                                                                الفردية<span class="text-danger" > *</span></label
                                                            >
                                                            <Field
                                                                type="number"
                                                                v-model="
                                                                    property[
                                                                        'single_beds'
                                                                    ]
                                                                "
                                                                placeholder="الأسرة الفردية"
                                                                name="single_beds"
                                                            />
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="single_beds"
                                                            />
                                                        </div>

                                                        <!-- Form Group -->
                                                        <div
                                                            class="form-group col-md-4"
                                                        >
                                                            <label
                                                                >عدد
                                                                الحمامات<span class="text-danger" > *</span></label
                                                            >
                                                            <Field
                                                                type="number"
                                                                v-model="
                                                                    property.bathrooms
                                                                "
                                                                placeholder="عدد الحمامات"
                                                                name="bathrooms"
                                                            />
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="bathrooms"
                                                            />
                                                        </div>

                                                        <!-- Form Group -->
                                                        <div
                                                            class="form-group col-md-4"
                                                        >
                                                            <label
                                                                >الضيوف
                                                                المتاحين<span class="text-danger" > *</span></label
                                                            >
                                                            <Field
                                                                type="number"
                                                                v-model="
                                                                    property.guests
                                                                "
                                                                placeholder="الضيوف المتاحين"
                                                                name="guests"
                                                            />
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="guests"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div id="map-wrapper">
                                                        <GoogleMap
                                                            :api-key="
                                                                $googleMapsApiKey
                                                            "
                                                            style="
                                                                width: 100%;
                                                                height: 500px;
                                                            "
                                                            :center="mapCenter"
                                                            :zoom="7"
                                                            @click="setMarker"
                                                        >
                                                            <Marker
                                                                :options="
                                                                    markerOptions
                                                                "
                                                                @dragend="
                                                                    setPosition
                                                                "
                                                            />
                                                        </GoogleMap>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="title">
                                                <h3>أسعار الفترات</h3>
                                            </div>
                                            <div class="row">
                                                <div
                                                    class="form-check form-check-inline"
                                                >
                                                    <label
                                                        class="form-check-label"
                                                    >
                                                        <input
                                                            class="form-check-input"
                                                            type="radio"
                                                            name="periods"
                                                            v-model="periods"
                                                            value="1"
                                                            checked
                                                        />
                                                        فترة واحدة
                                                    </label>
                                                    <label
                                                        class="form-check-label mr-2"
                                                    >
                                                        <input
                                                            class="form-check-input"
                                                            type="radio"
                                                            name="periods"
                                                            v-model="periods"
                                                            value="2"
                                                        />
                                                        فترتين
                                                    </label>
                                                    <label
                                                        class="form-check-label mr-2"
                                                    >
                                                        <input
                                                            class="form-check-input"
                                                            type="radio"
                                                            name="periods"
                                                            v-model="periods"
                                                            value="3"
                                                        />
                                                        ثلاث فترات
                                                    </label>
                                                </div>
                                            </div>

                                            <div
                                                class="row mb-1 align-items-center"
                                                v-if="periods >= 1"
                                            >
                                                <span class="col-md-3 col-lg-2"
                                                    >الفترة الأولى</span
                                                >
                                                <!-- Form Group -->
                                                <div
                                                    class="form-group col-md-4 col-lg-3"
                                                >
                                                    <Field
                                                        type="number"
                                                        v-model="
                                                            property.price_1
                                                        "
                                                        placeholder="السعر"
                                                        name="price_1"
                                                    />
                                                    <ErrorMessage
                                                        class="has-error"
                                                        name="price_1"
                                                    />
                                                </div>
                                                <!-- Form Group -->
                                                <div
                                                    class="form-group col-md-4 col-lg-3"
                                                >
                                                    <Field
                                                        type="text"
                                                        v-model="
                                                            property.note_1
                                                        "
                                                        placeholder="ملاحظة عن بداية ونهاية الفترة"
                                                        name="note_1"
                                                    />
                                                    <ErrorMessage
                                                        class="has-error"
                                                        name="note_1"
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                class="row mb-1 align-items-center"
                                                v-if="periods >= 2"
                                            >
                                                <span class="col-md-3 col-lg-2"
                                                    >الفترة الثانية
                                                </span>
                                                <!-- Form Group -->
                                                <div
                                                    class="form-group col-md-4 col-lg-3"
                                                >
                                                    <Field
                                                        type="number"
                                                        v-model="
                                                            property.price_2
                                                        "
                                                        placeholder="السعر"
                                                        name="price_2"
                                                    />
                                                    <ErrorMessage
                                                        class="has-error"
                                                        name="price_2"
                                                    />
                                                </div>
                                                <!-- Form Group -->
                                                <div
                                                    class="form-group col-md-4 col-lg-3"
                                                >
                                                    <Field
                                                        type="text"
                                                        v-model="
                                                            property.note_2
                                                        "
                                                        placeholder="ملاحظة عن بداية ونهاية الفترة"
                                                        name="note_2"
                                                    />
                                                    <ErrorMessage
                                                        class="has-error"
                                                        name="note_2"
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                class="row mb-1 align-items-center"
                                                v-if="periods >= 3"
                                            >
                                                <span class="col-md-3 col-lg-2"
                                                    >الفترة الثالثة
                                                </span>
                                                <!-- Form Group -->
                                                <div
                                                    class="form-group col-md-4 col-lg-3"
                                                >
                                                    <Field
                                                        type="number"
                                                        v-model="
                                                            property.price_3
                                                        "
                                                        placeholder="السعر"
                                                        name="price_3"
                                                    />
                                                    <ErrorMessage
                                                        class="has-error"
                                                        name="price_3"
                                                    />
                                                </div>
                                                <!-- Form Group -->
                                                <div
                                                    class="form-group col-md-4 col-lg-3"
                                                >
                                                    <Field
                                                        type="text"
                                                        v-model="
                                                            property.note_3
                                                        "
                                                        placeholder="ملاحظة عن بداية ونهاية الفترة"
                                                        name="note_3"
                                                    />
                                                    <ErrorMessage
                                                        class="has-error"
                                                        name="note_3"
                                                    />
                                                </div>
                                            </div>

                                            <h3>أسعار خاصة</h3>
                                            <hr />

                                            <div class="table-responsive">
                                                <table
                                                    id="special-prices-table"
                                                    class="table table-striped table-bordered table-hover"
                                                >
                                                    <thead class="bg-secondary">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>التاريخ</th>
                                                            <th
                                                                v-if="
                                                                    periods >= 1
                                                                "
                                                            >
                                                                سعر الفترة
                                                                الأولى
                                                            </th>
                                                            <th
                                                                v-if="
                                                                    periods >= 2
                                                                "
                                                            >
                                                                سعر الفترة
                                                                الثانية
                                                            </th>
                                                            <th
                                                                v-if="
                                                                    periods >= 3
                                                                "
                                                            >
                                                                سعر الفترة
                                                                الثالثة
                                                            </th>
                                                            <th>
                                                                <i
                                                                    class="fa fa-cogs"
                                                                ></i>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(
                                                                special, index
                                                            ) in property.specialPrices"
                                                            :key="index"
                                                        >
                                                            <td>{{ index }}</td>
                                                            <td>
                                                                <input
                                                                    class="form-control flatpicker"
                                                                    v-model="
                                                                        special.date
                                                                    "
                                                                />
                                                            </td>
                                                            <td
                                                                v-if="
                                                                    periods >= 1
                                                                "
                                                            >
                                                                <input
                                                                    type="number"
                                                                    class="form-control"
                                                                    v-model="
                                                                        special.price_1
                                                                    "
                                                                />
                                                            </td>
                                                            <td
                                                                v-if="
                                                                    periods >= 2
                                                                "
                                                            >
                                                                <input
                                                                    type="number"
                                                                    class="form-control"
                                                                    v-model="
                                                                        special.price_2
                                                                    "
                                                                />
                                                            </td>
                                                            <td
                                                                v-if="
                                                                    periods >= 3
                                                                "
                                                            >
                                                                <input
                                                                    type="number"
                                                                    class="form-control"
                                                                    v-model="
                                                                        special.price_3
                                                                    "
                                                                />
                                                            </td>
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-danger rounded"
                                                                    @click.prevent="
                                                                        deleteSpecialPrice(
                                                                            index
                                                                        )
                                                                    "
                                                                >
                                                                    <i
                                                                        class="la la-trash"
                                                                    ></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div class="row">
                                                <div class="col-12">
                                                    <button
                                                        type="button"
                                                        class="theme-btn btn-style-one rounded"
                                                        @click="addSpecialPrice"
                                                    >
                                                        <h3 class="text-white">
                                                            <i
                                                                class="la la-plus-circle"
                                                            ></i>
                                                        </h3>
                                                    </button>
                                                </div>
                                            </div>

                                            <h3>أسعار إضافية للحفلات</h3>
                                            <hr />
                                            <div class="row">
                                                <!-- Form Group -->
                                                <div
                                                    class="form-group col-md-4 col-lg-3"
                                                >
                                                    <label
                                                        >حفلة عيد ميلاد</label
                                                    >
                                                    <Field
                                                        type="number"
                                                        v-model="
                                                            property.birthday_party_price
                                                        "
                                                        placeholder="حفلة عيد ميلاد"
                                                        name="birthday_party_price"
                                                    />
                                                    <ErrorMessage
                                                        class="has-error"
                                                        name="birthday_party_price"
                                                    />
                                                </div>

                                                <!-- Form Group -->
                                                <div
                                                    class="form-group col-md-4 col-lg-3"
                                                >
                                                    <label>حفلة زواج</label>
                                                    <Field
                                                        type="number"
                                                        v-model="
                                                            property.wedding_party_price
                                                        "
                                                        placeholder="حفلة زواج"
                                                        name="wedding_party_price"
                                                    />
                                                    <ErrorMessage
                                                        class="has-error"
                                                        name="wedding_party_price"
                                                    />
                                                </div>

                                                <!-- Form Group -->
                                                <div
                                                    class="form-group col-md-4 col-lg-3"
                                                >
                                                    <label>حفلة أخرى</label>
                                                    <Field
                                                        type="number"
                                                        v-model="
                                                            property.other_party_price
                                                        "
                                                        placeholder="حفلة أخرى"
                                                        name="other_party_price"
                                                    />
                                                    <ErrorMessage
                                                        class="has-error"
                                                        name="other_party_price"
                                                    />
                                                </div>
                                            </div>

                                            <div class="title">
                                                <h3>المرافق الداخلية</h3>
                                            </div>
                                            <div class="row">
                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="air_conditioning"
                                                            id="air_conditioning"
                                                            true-value="1"
                                                            v-model="
                                                                property.air_conditioning
                                                            "
                                                        />
                                                        <label
                                                            for="air_conditioning"
                                                            >مكيف هواء</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="laundry"
                                                            id="laundry"
                                                            true-value="1"
                                                            v-model="
                                                                property.laundry
                                                            "
                                                        />
                                                        <label for="laundry"
                                                            >غسيل ملابس</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="sauna"
                                                            id="sauna"
                                                            true-value="1"
                                                            v-model="
                                                                property.sauna
                                                            "
                                                        />
                                                        <label for="sauna"
                                                            >ساونا</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="swimming_pool"
                                                            id="swimming_pool"
                                                            true-value="1"
                                                            v-model="
                                                                property.swimming_pool
                                                            "
                                                        />
                                                        <label
                                                            for="swimming_pool"
                                                            >مسبح</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="wifi"
                                                            id="wifi"
                                                            true-value="1"
                                                            v-model="
                                                                property.wifi
                                                            "
                                                        />
                                                        <label for="wifi"
                                                            >واى فاى</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="tv"
                                                            id="tv"
                                                            true-value="1"
                                                            v-model="
                                                                property.tv
                                                            "
                                                        />
                                                        <label for="tv"
                                                            >تليفزيون</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="parking"
                                                            id="parking"
                                                            true-value="1"
                                                            v-model="
                                                                property.parking
                                                            "
                                                        />
                                                        <label for="parking"
                                                            >موقف سيارات</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="minibar"
                                                            id="minibar"
                                                            true-value="1"
                                                            v-model="
                                                                property.minibar
                                                            "
                                                        />
                                                        <label for="minibar"
                                                            >ثلاجة
                                                            مشروبات</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="kitchen"
                                                            id="kitchen"
                                                            true-value="1"
                                                            v-model="
                                                                property.kitchen
                                                            "
                                                        />
                                                        <label for="kitchen"
                                                            >مطبخ</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="minikitchen"
                                                            id="minikitchen"
                                                            true-value="1"
                                                            v-model="
                                                                property.mini_kitchen
                                                            "
                                                        />
                                                        <label for="minikitchen"
                                                            >مطبخ صغير</label
                                                        >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="title">
                                                <h3>المرافق الخارجية</h3>
                                            </div>
                                            <div class="row">
                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="balcony"
                                                            id="balcony"
                                                            true-value="1"
                                                            v-model="
                                                                property.balcony
                                                            "
                                                        />
                                                        <label for="balcony"
                                                            >شرفة</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="garden_view"
                                                            id="garden_view"
                                                            true-value="1"
                                                            v-model="
                                                                property.garden_view
                                                            "
                                                        />
                                                        <label for="garden_view"
                                                            >إطلالة على
                                                            الحديقة</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="beach_view"
                                                            id="beach_view"
                                                            true-value="1"
                                                            v-model="
                                                                property.beach_view
                                                            "
                                                        />
                                                        <label for="beach_view"
                                                            >إطلالة على
                                                            البحر</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="kids_area"
                                                            id="kids_area"
                                                            true-value="1"
                                                            v-model="
                                                                property.kids_area
                                                            "
                                                        />
                                                        <label for="kids_area"
                                                            >منطقة ألعاب
                                                            أطفال</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="playground"
                                                            id="playground"
                                                            true-value="1"
                                                            v-model="
                                                                property.playground
                                                            "
                                                        />
                                                        <label for="playground"
                                                            >ملعب</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="barbecue_area"
                                                            id="barbecue_area"
                                                            true-value="1"
                                                            v-model="
                                                                property.barbecue_area
                                                            "
                                                        />
                                                        <label
                                                            for="barbecue_area"
                                                            >منطقة شوى</label
                                                        >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="title">
                                                <h3>حدد المسموح</h3>
                                            </div>
                                            <div class="row">
                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="smoking"
                                                            id="smoking"
                                                            true-value="1"
                                                            v-model="
                                                                property.smoking
                                                            "
                                                        />
                                                        <label for="smoking"
                                                            >التدخين</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="kids"
                                                            id="kids"
                                                            true-value="1"
                                                            v-model="
                                                                property.kids
                                                            "
                                                        />
                                                        <label for="kids"
                                                            >إصطحاب
                                                            الأطفال</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="pets"
                                                            id="pets"
                                                            true-value="1"
                                                            v-model="
                                                                property.pets
                                                            "
                                                        />
                                                        <label for="pets"
                                                            >إصطحاب
                                                            الحيوانات</label
                                                        >
                                                    </div>
                                                </div>

                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <div class="check-box">
                                                        <input
                                                            type="checkbox"
                                                            name="parties"
                                                            id="parties"
                                                            true-value="1"
                                                            v-model="
                                                                property.parties
                                                            "
                                                        />
                                                        <label for="parties"
                                                            >الحفلات</label
                                                        >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="title">
                                                <h3>معرض الصور</h3>
                                            </div>
                                            <div
                                                class="row"
                                                id="images-wrapper"
                                            >
                                                <!-- Form Group -->
                                                <div
                                                    class="col-md-4 col-lg-3 img-block mt-2"
                                                    v-for="(
                                                        image, index
                                                    ) in property.images"
                                                    :key="index"
                                                >
                                                    <input
                                                        type="file"
                                                        class="dropify"
                                                        name="images[]"
                                                        :data-id="image.id"
                                                        :data-default-file="
                                                            image.thumb
                                                        "
                                                    />
                                                </div>

                                                <div
                                                    class="col-md-4 col-lg-3 img-block mt-2"
                                                >
                                                    <input
                                                        type="file"
                                                        class="dropify toBeAdded"
                                                        name="images[]"
                                                    />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <button
                                                        type="button"
                                                        class="theme-btn btn-style-one rounded"
                                                        @click="addImage"
                                                    >
                                                        <h3 class="text-white">
                                                            <i
                                                                class="la la-plus-circle"
                                                            ></i>
                                                        </h3>
                                                    </button>
                                                </div>
                                            </div>

                                            <hr />
                                            <div class="row">
                                                <div
                                                    class="form-group col-lg-9 col-md-6 col-sm-12"
                                                >
                                                    <label>
                                                        نسبة الخصم عند الإلغاء %
                                                    </label>
                                                    <Field
                                                        type="number"
                                                        v-model="
                                                            property.cancelation_deduction
                                                        "
                                                        placeholder="نسبة الخصم عند الإلغاء %"
                                                        name="cancelation_deduction"
                                                        class="form-control"
                                                    />
                                                    <ErrorMessage
                                                        class="has-error"
                                                        name="cancelation_deduction"
                                                    />
                                                </div>
                                                <div
                                                    class="form-group col-lg-3 col-md-6 col-sm-12"
                                                >
                                                    <button
                                                        type="submit"
                                                        class="theme-btn btn-style-one"
                                                    >
                                                        تعديل الإعلان
                                                    </button>
                                                </div>
                                            </div>
                                        </vee-form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import PropertyFormValidation from "../../forms/propertyFormValidation";
import { GoogleMap, Marker } from "vue3-google-map";
import { ElSwitch } from "element-plus";
import flatpickr from "flatpickr";
import { Arabic } from "flatpickr/dist/l10n/ar.js";
import "flatpickr/dist/flatpickr.css";

export default {
    name: "my.edit-property",
    components: {
        "vee-form": Form,
        Field,
        ErrorMessage,
        GoogleMap,
        Marker,
        ElSwitch,
    },
    data() {
        return {
            periods: 1,
            property: {},
            categories: [],
            PropertyFormValidation,
            mapCenter: {},
            markerOptions: {},
            governorates: {
                مسقط: ["مسقط", "مطرح", "العامرات", "بوشر", "السيب", "قريات"],
                ظفار: [
                    "صلالة",
                    "طاقة",
                    "مرباط",
                    "رخيوت",
                    "ثمريت",
                    "ضلكوت",
                    "المزيونة",
                    "مقشن",
                    "سدح",
                    "شليم وجزر الحلانيات",
                ],
                مسندم: ["خصب", "دبا", "بخا", "مدحاء"],
                البريمى: ["البريمى", "محضة", "السنينة"],
                الداخلية: [
                    "نزوى",
                    "بهلا",
                    "منح",
                    "الحمراء",
                    "أدم",
                    "إزكى",
                    "سمائل",
                    "بدبد",
                ],
                "شمال الباطنة": [
                    "صحار",
                    "شناص",
                    "لوى",
                    "صحم",
                    "الخابورة",
                    "السويق",
                ],
                "جنوب الباطنة": [
                    "الرستاق",
                    "العوابى",
                    "نخل",
                    "وادى المعاول",
                    "بركاء",
                    "المصنعة",
                ],
                "جنوب الشرقية": [
                    "صور",
                    "الكامل والوافى",
                    "جعلان بنى بوحسن",
                    "جعلان بنى بوعلى",
                    "مصيرة",
                ],
                "شمال الشرقية": [
                    "إبراء",
                    "المضيبى",
                    "بدية",
                    "القابل",
                    "وادى بنى خالد",
                    "دماء الطائيين",
                ],
                الظاهرة: ["عبرى", "ينقل", "ضنك"],
                الوسطى: ["هيما", "محوت", "الدقم", "الجازر"],
            },
        };
    },
    async mounted() {
        await this.$axios
            .get("/property/" + this.$route.params.id)
            .then((res) => {
                this.categories = res.data.categories;
                this.property = res.data.property;
                this.property.imagesToDelete = [];
                this.property.imagesToAdd = [];

                this.mapCenter = {
                    lat: this.property.lat,
                    lng: this.property.lng,
                };

                this.markerOptions = {
                    draggable: true,
                    position: {
                        lat: this.property.lat,
                        lng: this.property.lng,
                    },
                };

                if (this.property.price_3) {
                    this.periods = 3;
                } else if (this.property.price_2) {
                    this.periods = 2;
                }
            });

        this.$nextTick(() => {
            this.initDropify();
            let date = new Date();

            flatpickr(".flatpicker", {
                locale: Arabic,
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
                minDate:
                    date.getFullYear() +
                    "-" +
                    (date.getMonth() + 1) +
                    "-" +
                    date.getDate(),
            });
        });
    },
    methods: {
        initPosition() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    let pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };

                    this.mapCenter = pos;

                    this.property.lat = pos.lat;
                    this.property.lng = pos.lng;

                    this.markerOptions = {
                        draggable: true,
                        position: pos,
                    };
                });
            }
        },
        setPosition(e) {
            this.mapCenter = e.latLng;
            this.property.lat = e.latLng.lat();
            this.property.lng = e.latLng.lng();
        },
        setMarker(e) {
            this.mapCenter = e.latLng;

            this.markerOptions = {
                draggable: true,
                position: e.latLng,
            };

            this.setPosition(e);
        },
        addSpecialPrice() {
            let now = new Date();

            this.property.specialPrices.push({
                date:
                    now.getFullYear() +
                    "-" +
                    (now.getMonth() + 1) +
                    "-" +
                    now.getDate(),
                price_1: this.property.price_1 ?? 0,
                price_2: this.property.price_2 ?? 0,
                price_3: this.property.price_3 ?? 0,
            });

            this.$nextTick(() => {
                flatpickr(".flatpicker", {
                    locale: Arabic,
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d",
                    defaultDate: Date.now(),
                    minDate: Date.now(),
                });
            });
        },
        deleteSpecialPrice(index) {
            this.property.specialPrices.splice(index, 1);
        },
        initDropify(element = null) {
            let $this = this;
            $(element ?? ".dropify")
                .dropify({
                    messages: {
                        default: "أضف صورة",
                        replace: "إستبدال",
                        remove: "إزالة",
                        error: "حدث خطأ ما",
                    },
                })
                .on("dropify.afterClear change", function (e) {
                    if (e.type == "change" && e.target.files.length > 0) {
                        $(this).addClass("hasImage");

                        $this.toBase64(e.target.files[0]).then((image) => {
                            $this.property.imagesToAdd.push(image);
                        });
                    }

                    if (typeof $(this).data("id") != "undefined") {
                        $this.property.imagesToDelete.push($(this).data("id"));
                    }

                    if (e.type == "dropify") {
                        if ($(this).hasClass("toBeAdded")) {
                            $this.property.imagesToAdd.splice(
                                $(".dropify.toBeAdded.hasImage").index(this),
                                1
                            );
                        }

                        $(this).closest(".img-block").remove();
                    }
                });
        },
        addImage() {
            let input = $(`<div
                            class="col-md-4 col-lg-3 img-block mt-2"
                        >
                            <input
                                type="file"
                                class="dropify toBeAdded"
                                name="images[]"
                            />
                        </div>`);
            $("#images-wrapper").append(input);

            this.initDropify(input.find(".dropify"));

            input.find('input[type="file"]').click();
        },
        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        submit() {
            this.$axios.put("my/properties/" + this.property.id, this.property);
        },
    },
    computed: {
        availableStates() {
            return this.property.governorate
                ? this.governorates[this.property.governorate]
                : [];
        },
    },
};
</script>

<style>
@import "../../../../../node_modules/dropify/dist/css/dropify.css";
@import "../../../../../node_modules/element-plus/lib/theme-chalk/index.css";
</style>